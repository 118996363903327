:root, html {
	--header-height: 5rem;
	background-color: hsl(var(--header-bg, 195deg 5% 15%));
	font-size: calc(var(--font-size));
	--font-size: 18px/* min(16px + min(0.125vw, .25vh)) */;
	--page-width: min(var(--font-size) * 60);
	--t: 0.5s ease;
	--transitions-btn:
		transform var(--t),
		opacity var(--t),
		color var(--t),
		background-color var(--t);
}


@media (max-width: 1600px) AND (min-height:800px) {
	:root, html {
		--font-size: min(10px + min(0.5vw, 1vh));
		--page-width: min(var(--font-size) * 50)
	}
}

@media (max-width: 1200px), (max-height:700px) {
	:root, html {
		--font-size: min(7px + min(.75vw, 1.28vh));
		--page-width: min(var(--font-size) * 40)
	}
}

@media (max-width: 600px), (max-height:600px) {
	:root, html {
		--font-size: min(2.5px + min(1.5vw, 1.5vh));
		--page-width: min(var(--font-size) * 30, 100vw - 2em)
	}
}

@media (max-width: 360px) {
	:root, html {
		--font-size: min(2.2vw, 4.4vh)
	}
}

@media print {
	:root, html {
		font-size: calc(var(--font-size) * 0.9);
	}

}
.light-dark-toggle {
	position: relative;
	display: inline-block;
	width: 3rem;
	background-color: hsl(var(--header-bg, 195deg 5% 15%));
}

.page-width-content {
	width: var(--page-width);
}

.App {
	text-align: center;
}


header {
	background-color: hsl(var(--header-bg, 195deg 5% 20%)/.6);
	color: hsl(var(--header-fg, 195deg 25% 98%));
	box-shadow: 0 0 .2rem #000;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	height: 5rem;
	padding: .5rem min(50% - 0.5 * var(--page-width, 80vw));
	z-index: 5;
	/* display: flex; */
	/* flex-direction: row; */
	align-items: center;
	/* justify-content: space-between; */
	display: grid;
    align-content: center;
	gap: 1rem;
}
@media screen {
	header {
		position: fixed;
	}
}
@supports (display: grid) {
	header {
		/* grid-template-columns: 1fr auto 1fr; */
		grid-template: "a b c" var(--header-height) / auto auto auto;
	}
}
@supports NOT (display: grid) {
	header {
		grid-template: "a b c" var(--header-height) / repeat(3, min(var(--page-width) * .333333));
	}
}

header .logo {
	height: 2em;
	pointer-events: none;
}
.header-logo {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	font-size: 3rem;
}
.header-logo h1 {
	font-size: inherit;
	margin: 0 0 0 .1em;
	text-align: left;
	line-height: 1;
}

.logo svg {
	width: 100%;
	height: 100%;
	fill: currentColor
}

.header-text {
	font-size: 1.75rem;
	height: auto;
	display: flex;
	flex-direction: row;
	gap: .1em;
	justify-content: center;
}


.header-btns {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: .5rem;
}
.print-btn {
	--col: 195deg 15% 50%;
	/* background-color: rgb(0 0 0/ .2); */
	background-color: hsl(var(--col)/.3);
	color: rgb(var(--fg)/ .7);
	border: solid .1rem hsl(var(--col)/.6);
	font-size: 1.5rem;
	padding: .25rem 1.5rem;
	border-radius: .75em;
	line-height: 1;
	width: auto;
	text-transform: uppercase;
	transition: var(--transitions-btn);
	cursor: pointer;
}

.print-btn:hover,
.print-btn:focus {
	background-color: rgb(0 0 0/ .4);
	background-color: hsl(var(--col)/.5);
	color: rgb(0 0 0);
}
.light-dark-toggle {
	position: relative;
	--col: 195deg 15% 50%;
	background-color: hsl(var(--col)/.4);
	border-radius: .6em;
	border: solid .1em hsl(var(--col)/.6);
	width: 2em;
	height: 1em;
	font-size: 1rem;
	cursor: pointer;
	transition: 
		left var(--t),
		background-color var(--t);
}
.light-dark-toggle:hover,
.light-dark-toggle:focus {
	background-color: hsl(var(--col)/.6);
}
.light-dark-toggle>* {
	position: absolute;
	background-color: hsl(var(--col)/.6);
	width: 1em;
	height: 1em;
	border-radius: inherit;
	z-index: 2;
	transition: 
		left var(--t),
		background-color var(--t);
}
.light-dark-toggle:after {
	position: absolute;
	display: inline-block;
	border-radius: inherit;
	z-index: 1;
	content: '';
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	border-left: solid .4em hsl(195deg 50% 95%/.6);
	border-right: solid .4em hsl(195deg 50% 5%/.6);
}
.light-dark-toggle--auto>*{left: .5em}
.light-dark-toggle--dark>*{left: 1em; background-color: hsl(195deg 50% 5%/.6);}
.light-dark-toggle--light>*{left: 0px; background-color: hsl(195deg 50% 95%/.6);}


main {
	background-color: hsl(var(--main-bg, 195deg 5% 10%));
	color: hsl(var(--fg, 0 0% 100%));
	padding: 4rem 0 0;
	position: relative;
	z-index: 1;
}

aside {
	background-color: hsl(var(--aside-bg, 195deg 5% 10%));
	color: hsl(var(--fg, 0 0% 100%));
	padding: 4rem 0 0;
}

aside>section,
main>section {
	padding: 3rem min(50% - 0.5 * var(--page-width, 80vw))
}

footer {
	background-color: hsl(var(--footer-bg, 195deg 5% 15%));
	color: hsl(var(--footer-fg, 195deg 25% 98%));
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: .5rem min(50% - 0.5 * var(--page-width, 80vw));
}

#page_wrap {
	display: flex;
	gap: 0;
	flex-direction: column;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 400;
	/* letter-spacing: .05em; */
	line-height: 1.2;
}
h1 {font-size: 2.5rem}
h2 {font-size: 2.0rem}
h3 {font-size: 1.6rem}
h4 {font-size: 1.3rem}
h5 {font-size: 1.1rem}
h6 {font-size: 1.0rem}

.small-text {
	font-size: 0.8rem;
}

header a,
footer a {
	color: inherit;
	text-decoration: none;
}

/* @media (prefers-color-scheme: light), print { */
	header {
		color: hsl(var(--header-bg, 195deg 5% 20%)/1);
		background-color: hsl(var(--header-fg, 195deg 25% 98%));
	}

	/* footer {main.page-calc {

		color: hsl(var(--footer-bg, 195deg 5% 15%));
		background-color: hsl(var(--footer-fg, 195deg 25% 98%));
	} */
	main {
		color: hsl(var(--fg, 195deg 5% 10%));
		background-color: hsl(var(--main-bg, 0 0% 100%));
	}
	
/* } */
@media screen and (prefers-color-scheme: dark) {
	html:has(.App:not(.color-scheme-light)) {
		--header-bg: 195deg 5% 15%;
		--header-fg: 195deg 25% 98%;
		--bg: 195deg 5% 10%;
		--main-bg: var(--bg);
		--fg: 0 0% 100%;
		--footer-bg: 195deg 5% 15%;
		--footer-fg: 195deg 25% 98%;
	}
	.App:not(.color-scheme-light) header {
		background-color: hsl(var(--header-bg, 195deg 5% 20%)/.6);
		color: hsl(var(--header-fg, 195deg 25% 98%));
	}

	/* footer {
		color: hsl(var(--footer-bg, 195deg 5% 15%));
		background-color: hsl(var(--footer-fg, 195deg 25% 98%));
	} */
	.App:not(.color-scheme-light) main {
		background-color: hsl(var(--main-bg, 195deg 5% 10%));
		color: hsl(var(--fg, 0 0% 100%));
	}
	.App:not(.color-scheme-light) .print-btn {
		background-color: rgb(255 255 255/ .2);
		color: rgb(255 255 255/ .7);
	}
	
	.App:not(.color-scheme-light) .print-btn:hover,
	.App:not(.color-scheme-light) .print-btn:focus {
		background-color: rgb(255 255 255/ .4);
		color: rgb(255 255 255);
	}
	
}
@media screen {

	html:has(.color-scheme-dark),:root:has(.color-scheme-dark),.App.color-scheme-dark {
		--header-bg: 195deg 5% 15%;
		--header-fg: 195deg 25% 98%;
		--bg: 195deg 5% 10%;
		--main-bg: var(--bg);
		--fg: 0 0% 100%;
		--footer-bg: 195deg 5% 15%;
		--footer-fg: 195deg 25% 98%;
	}
.App.color-scheme-dark	header {
		background-color: hsl(var(--header-bg, 195deg 5% 20%)/.6);
		color: hsl(var(--header-fg, 195deg 25% 98%));
	}

	/* footer {
		color: hsl(var(--footer-bg, 195deg 5% 15%));
		background-color: hsl(var(--footer-fg, 195deg 25% 98%));
	} */
.App.color-scheme-dark	main {
		background-color: hsl(var(--main-bg, 195deg 5% 10%));
		color: hsl(var(--fg, 0 0% 100%));
	}
.App.color-scheme-dark	.print-btn {
		background-color: rgb(255 255 255/ .2);
		color: rgb(255 255 255/ .7);
	}
	
.App.color-scheme-dark	.print-btn:hover,
.App.color-scheme-dark	.print-btn:focus {
		background-color: rgb(255 255 255/ .4);
		color: rgb(255 255 255);
	}}
@media print {
	header {
		box-shadow: 0 0 0 transparent;
	}
	html, body, main, #root, #root>.App, #page_wrap, #page_wrap>.page-calc {
		align-items: center;
		justify-content: center;
		/* max-width: min(99vw, 100% - 1px);
		max-height: min(99vh, 100% - 1px); */
		height: auto;
	}
	footer,
	.header-btns {
		display: none;
	}
}