@media screen{
*:has(>.background-img) {
    position: relative;
    z-index: 2;
}
main>.background-img:after {
    background-color: hsl(var(--main-bg, 195deg 15% 20%)/var(--main-bg-op,.3));
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline-block;
    content: ' ';
}
.background-img img{
    position: absolute;
    top: min(50% - 50vh, 0px);
    bottom: min(50% - 50vw, 0px);
}
@supports (object-fit: cover) AND (object-position: center) {
    .background-img img{
        object-fit: cover;
        object-position: center;
        min-width: 100%;
        min-height: 100%;
        top: unset;
        bottom: unset;
        position: unset;
    }
}
.background-img{
    position: absolute;
    overflow: hidden;
    display: block;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
*:has(>.background-img)>.background-img {
    position: absolute;
    overflow: hidden;
    display: block;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
*:has(>.background-img)>*{
    position: relative;
    z-index: 2;
}
}
@media print {
    .background-img {
        display: none;
    }
}