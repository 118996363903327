
main.page-calc {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: space-around;
}
main.page-calc section {
    width: auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: flex-start;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    min-width: min(var(--page-width) * .5 - 1em);
}

main.page-calc {
    --main-bg: 195deg 15% 99%;
    --main-bg-op: .3;
    --field-bg: 195deg 25% 30%;
    --field-bd: 195deg 5% 95%;
    --field-fg: 195deg 5% 95%;
    --fg: 195deg 25% 1%;
    --highlight-col: hsl(195deg 60% 60%);
    --chart-col-1: hsl(195deg 30% 60%);
    --chart-col-2: hsl(5deg 30% 60%);
}

/* aside.calc-results {
    --aside-bg: 195deg 15% 20%;
    --aside-bg-op: .3;
    --fg: 195deg 25% 99%;
    color: var(--fg)
} */

.inputs_wrap {
    display: grid;
    grid-template:
        "heading heading"auto "panelProps usage"auto "panelQty usage"auto "price usage"auto / 1fr 1fr;
    gap: 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.inputs_wrap__inner {
    gap: 1em;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto
}



.input__field__input {
    all: unset;
    text-align: right;
    padding: 0 .2em !important;
    display: inline-block;
    width: auto;
}

/* .page-calc h1 {font-size: 3.0rem} */
/* .page-calc h2 {font-size: 2.5rem} */
/* .page-calc h3 {font-size: 2.0rem} */
/* .page-calc h4 {font-size: 1.6rem} */
/* .page-calc h5 {font-size: 1.3rem} */
/* .page-calc h6 {font-size: 1.1rem} */

.page-calc *:is(h1, h2, h3, h4, h5, h6) {
    margin: 0
}

.inputs__heading {
    grid-area: heading;
}

.inputs__panel_props {
    grid-area: panelProps;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 1em
}

.inputs__panel_qty {
    grid-area: panelQty;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 1em
}

.inputs__panel_qty .input__field__inner{
    width: 2em;
    min-width: 0;
}
.inputs__panel_qty input {
    width: 100%
}

.inputs__panel_qty .input__field__unit_after {
    display: none;
}

.inputs__panels h4 {
    grid-area: heading;
}

.inputs__price {
    grid-area: price;
    display: grid;
    gap: .5rem;
    justify-items: center;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.inputs__usage {
    grid-area: usage;
    display: grid;
    gap: .5rem;
    justify-items: center;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.highlight {
    font-weight: 300;
    font-size: 3rem;
    color: hsl(var(--fg));
    /* text-decoration: double underline var(--highlight-col); */
    position: relative;
    line-height: 1;
    margin: .5rem
}
.highlight .report__unit {
    font-weight: 500;
    margin-left: .25rem;
    position: absolute;
    left: 100%;
    bottom: 0;
    font-size: 1.2rem
}
.highlight .report__unit--pre {
    right: 100%;
    top: 0;
    left: unset;
    bottom: unset;
}

.outputs--result {
    text-transform: uppercase;
    border: .2rem solid var(--highlight-col);
    padding: .5rem 1rem;
    border-radius: 1rem;
    width: auto;
    margin: 1rem auto 0;
}
.avg_red_result {
    font-size: 1.5rem
}

.inputs__direction {
    display: grid;
    gap: 0.5rem;
}

.inputs__direction--north {grid-area: north}

.inputs__direction--east {grid-area: east}

.inputs__direction--west {grid-area: west}

.inputs__direction--south {grid-area: south}

.inputs__usage .input__field__label {
    min-width: 5em;
}

.input__field__label {
    min-width: 5em;
    text-align: right;
    /* padding: 0 .5em 0 1em; */
    padding: 0 .25rem 0 0;
    /* font-size: 80%; */
    /* transform: scaleX(.9); */
    transform-origin: right;
    letter-spacing: -.05em;
}
.input__field__label--facing {
    min-width: 6em;
}

.input__field__inner,
.input__field__inc-dec {
    background-color: transparent;
    color: inherit;
    padding: 0;
    border: 0px none;
    font: inherit;
    height: 100%;
    width: 1.5em;
    border-left: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.input__field__inc-dec :is(.input__field__increment,.input__field__decrement) {
    border: 0px none;
    height: 50%;
    width: 100%;
    padding: 0 .25rem 0 0;
    cursor: pointer;
}

.input__field__inc-dec :is(.input__field__increment,.input__field__decrement):is(:hover,:focus) {
    background-color: hsl(var(--field-fg, 195deg 25% 30%)/.2);
    color: hsl(var(--field-fg, 195deg 5% 95%)/.8);

}

.input__field__inc-dec {
    flex-direction: column;
    gap: 0;
}

.input__field__inner {
    min-width: 4.25em;
    justify-content: flex-end;
    padding: 0 .25em
}

.input__field {
    display: flex;
    justify-items: center;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    gap: 0;
    overflow: hidden;
}

.input__field.input__field--btn {
    padding: .25em .75em;
    font-size: 1rem;

}

.input__field--unit_before {
    grid-template: "label unit input"auto / 1fr 6rem 1rem;
}

.input__field--unit_after {
    grid-template: "label input unit"auto / 1fr 6rem 1rem;
}

.input__field:after {
    text-align: left;
    width: 1em
}

.input__field__unit_before {
    text-align: right;
}

.input__field__unit_after {
    text-align: left;
    font-size: 70%;
    /* opacity: .8; */
    min-width: .6rem;
    width: auto;
    position: relative;
    top: 0rem;
}

.input__field__unit_before:before {
    content: var(--unit_before)
}

.input__field__unit_after:empty:before {
    content: var(--unit_after)
}

.input__field__unit_after,
.input__field__unit_before {
    display: inline-block;
}

.input__field--power {
    --unit_after: "W"
}

.input__field--angle {
    --unit_after: "°"
}

.input__field--shade {
    --unit_after: '%'
}

.input__field--usage {
    --unit_after: 'kWh'
}

.input__field--price {
    --unit_after: '¢/kWh'
}


.input__field__unit_after {
    text-align: left;
    align-items: flex-start;
}
#field-kwhPrice
.input__field__unit_after {
    min-width: 1.7rem;
}
.inputs__usage
.input__field__unit_after {
    min-width: 1.3rem;
}

.inputs__usage
.input__field__input {
    width: 3rem;
}
.inputs__usage
.input__field__inner {
    width: 4rem;
}

.input__field__input {
    width: 4.5em;
}

#input-price {
    width: auto;
}

/* Chrome, Safari, Edge, Opera */
.input__field__input::-webkit-outer-spin-button,
.input__field__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.input__field__input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.input__field {
    background: hsl(var(--field-bg, 195deg 25% 30%)/.7);
    padding: 0;
    border-radius: 1em;
    line-height: 1;
    border: .1em solid hsl(var(--field-bd, 195deg 5% 95%)/.8);
    height: 1.5em;
    color: hsl(var(--field-fg, 195deg 5% 95%)/.8);
}

::placeholder {
    color: hsl(var(--field-fg, 195deg 5% 95%)/.6);
    opacity: 1;
    /* Firefox */
}

::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: hsl(var(--field-fg, 195deg 5% 95%)/.6);
}

@media (max-width: 1280px) {
    .inputs__panels {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-content: center;
        justify-content: center;
        align-items: center;
        gap: 1em
    }
}

.fields__wrap {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.bar-graph__bar {
    stroke: none;
    stroke-width: 0;
}

.bar-graph__bar.usage-month {
    fill: var(--chart-col-1)
}

.bar-graph__label.usage-month--usage {
    /* fill: #fff; */
    stroke: var();
    paint-order: stroke fill;
}


/* .bar-graph__bar.usage-month--excess {
    fill: hsl(15deg 40% 50%)
} */

.bar-graph__label {
    fill: currentColor
}


.line-graph {
    stroke: var(--chart-col-2);
    stroke-width: .5;
    fill: transparent;
}

.graph-grid {
    stroke: hsl(0 0% 100%/.6);
    stroke-width: 0.125;
    fill: transparent;
}
.graph-grid__label {
    fill: hsl(0 0% 100%/.6)
}

*:has(>[tooltip]) {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    /* cursor: help; */
}
[tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    top: 3em;
    padding: .25em .5em;
    background-color: hsl(0 0% 0%/.6);
    color: hsl(0 0% 100%/.6);
    /* font-size: 80%; */
    border-radius: .25em;
    pointer-events: none;
    opacity: 0;
    transition: opacity .2s ease-in-out, top .2s ease-in-out;
    z-index: 2;
    max-width: unset;
    width: auto;
}
[tooltip]:hover:before {
    top: 2em;
    transition: opacity .5s .2s ease-in-out, top .5s .2s ease-in-out;
    opacity: 1;
}

@media screen {
    .graph-grid,
    .line-graph {
        /* filter: drop-shadow(0 0 .1vw #000) */
    }
}


.table_wrap {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: center;
    justify-content: center;
}

.table_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: auto;
}
.table_row--total {
    padding-top: .5rem;
    font-weight: bolder;
}

.table_cell {
    display: flex;
    flex-direction: column
}

span.table_cell {
    display: inline-block;
    ;
}
.calculation_report__outputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.calculation_report .outputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:.2em
}
.calculation_report .table_wrap {
    width: 18rem;
}
main.page-calc section.calculation_report {
    display: grid;
    grid-template: "outputs notes" auto / 1fr 1fr;
}

.calculation_report__bill_reduction,
.calculation_report__generation {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:inherit
}

.calculation_report__bill_reduction .outputs,
.calculation_report__generation .outputs {
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.calculation_report__bill_reduction .note,
.calculation_report__generation .note {
    width: 20rem;
    display: block;
    text-align: left;
}

.calculation_report__bill_reduction .table_wrap,
.calculation_report__generation .table_wrap {
    width: 18rem;
}

.calculation_report {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    min-width: min(var(--page-width) * .5 - 1em);
}

.report__unit {
    opacity: 1.8;
    margin-left: .5em;
    font-size: 60%;
}

.report__unit--pre {
    position: relative;
    top: -.5em;
}

.outputs_wrap svg {
    width: min(max(35vw,80vh), var(--page-width));
}

.outputs_graph {
}
.inputs_wrap__inner--panels_and_price {
    display: grid;
    grid-template:
        "panels qty" auto
        "price qty" auto
        "none none" 1fr / 1fr 1fr;
    align-items: start;
    height: auto;
}
.inputs__panel_props {grid-area: panels}
.inputs__panel_qty {grid-area: qty}
.inputs__price {grid-area: price}

.inputs__panel_props h4,
.inputs__panel_qty h4,
.inputs__usage h4,
.calculation_report__outputs h4 {
    text-decoration: underline .1em solid hsl(var(--fg));
}

@media (min-aspect-ratio: 16/9) {
    main.page-calc {
        padding: min(var(--header-height) + 2rem) min(25% - var(--page-width) * 0.25) 3rem;
        gap: 2rem;
        padding: min(var(--header-height) + 2rem) 4rem 3rem;
        display: grid;
        grid-template: 
            "inputs_wrap outputs_graph" auto
            "calculation_report outputs_graph" auto
            "calculation_report info" auto / 11fr 9fr;
        justify-items: center;
    }
    .inputs__usage .fields__wrap {
        flex-wrap: wrap;
        height: 13rem;
    }
    /* .inputs_wrap .inputs_wrap__inner--panels_and_price {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 25rem;
        justify-content: space-evenly;
        align-content: flex-start;
        align-items: flex-start;
    } */
    .inputs_wrap {grid-area: inputs_wrap}
    main.page-calc .info-instruct {grid-area: info; min-width: 0; width: 100%; align-content: center;}
    .info-instruct>div {max-width: min(100% - 1rem, 35vw); margin: auto}
    .calculation_report {grid-area: calculation_report}
    .outputs_graph {grid-area: outputs_graph}
    

    .outputs_graph svg {
        width: min(35vw, var(--page-width));
    }
}
@media (max-aspect-ratio: 3/4) {
    main.page-calc {
        padding: min(var(--header-height) + 2rem) 4rem 3rem;
    }
    .inputs__usage .fields__wrap {
        flex-wrap: nowrap;
        height: auto;
    }
    .inputs_wrap {
        display: grid;
        grid-template: "a b" auto / 1fr 1fr;
        width: auto;
        align-items: start;
        justify-items: center;
    }
    .inputs_wrap .inputs_wrap__inner--usage {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: auto;
        justify-content: space-evenly;
        align-content: flex-start;
        align-items: flex-start;
    }
    .calculation_report__outputs {
        flex-direction: column;
    }
    .inputs_wrap__inner--panels_and_price {
        display: grid;
        grid-template:
            "panels qty" auto
            "price qty" auto
            "none none" 1fr / 1fr 1fr;
        align-items: start;
        height: auto;
        display: flex;
        flex-direction: column;
    }
}
/* @media (prefers-color-scheme: light), print { */
    .input__field {
        color: hsl(var(--field-bg, 195deg 25% 30%));
        border-color: 0.1em solid hsl(var(--field-bd, 195deg 5% 95%)/.8);
        background-color: hsl(var(--field-fg, 195deg 5% 95%)/.8);
    }
    ::placeholder {
        color: hsl(var(--field-fg, 195deg 25% 30%)/.6);
        opacity: 1;
        /* Firefox */
    }
    ::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: hsl(var(--field-fg, 195deg 25% 30%)/.6);
    }
    .graph-grid__label {
        fill: hsl(0 0% 0%/.6)
    }
    .graph-grid {
        stroke: hsl(0 0% 0%/.6)
    }
/* } */
@media screen and (prefers-color-scheme: dark){
    .App:not(.color-scheme-light) main.page-calc {
        --main-bg: 195deg 15% 20%;
        --main-bg-op: .3;
        --field-bg: 195deg 25% 30%;
        --field-bd: 195deg 5% 95%;
        --field-fg: 195deg 5% 95%;
        --fg: 195deg 25% 99%;
        --highlight-col: hsl(195deg 35% 45%);
        --chart-col-1: hsl(195deg 30% 40%);
        --chart-col-2: hsl(5deg 30% 40%);
    }
    .App:not(.color-scheme-light) .input__field {
        background-color: hsl(var(--field-bg, 195deg 25% 30%)/.7);
        border-color: .1em solid hsl(var(--field-bd, 195deg 5% 95%)/.8);
        color: hsl(var(--field-fg, 195deg 5% 95%)/.8);
    }
    .App:not(.color-scheme-light) ::placeholder {
        color: hsl(var(--field-fg, 195deg 25% 30%)/.6);
        opacity: 1;
        /* Firefox */
    }
    .App:not(.color-scheme-light) ::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: hsl(var(--field-fg, 195deg 25% 30%)/.6);
    }
    .App:not(.color-scheme-light) .graph-grid__label {
        fill: hsl(0 0% 100%/.6)
    }
    .App:not(.color-scheme-light) .graph-grid {
        stroke: hsl(0 0% 100%/.6)
    }
}
@media screen {
.App.color-scheme-dark    main.page-calc {
        --main-bg: 195deg 15% 20%;
        --main-bg-op: .3;
        --field-bg: 195deg 25% 30%;
        --field-bd: 195deg 5% 95%;
        --field-fg: 195deg 5% 95%;
        --fg: 195deg 25% 99%;
        --highlight-col: hsl(195deg 35% 45%);
        --chart-col-1: hsl(195deg 30% 40%);
        --chart-col-2: hsl(5deg 30% 40%);
    }
.App.color-scheme-dark    .input__field {
        background-color: hsl(var(--field-bg, 195deg 25% 30%)/.7);
        border-color: .1em solid hsl(var(--field-bd, 195deg 5% 95%)/.8);
        color: hsl(var(--field-fg, 195deg 5% 95%)/.8);
    }
.App.color-scheme-dark    ::placeholder {
        color: hsl(var(--field-bg, 195deg 25% 30%)/.6);
        opacity: 1;
        /* Firefox */
    }
.App.color-scheme-dark    ::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: hsl(var(--field-bg, 195deg 25% 30%)/.6);
    }
.App.color-scheme-dark    .graph-grid__label {
        fill: hsl(0 0% 100%/.6)
    }
.App.color-scheme-dark    .graph-grid {
        stroke: hsl(0 0% 100%/.6)
    }
}
@media print {
    .input__field {
        border-width: 0px;
        background: transparent;
        padding: 0px;
        height: auto;
        color: #000
    }
    .inputs_wrap__inner--panels_and_price {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-content: flex-start;
        align-items: flex-start;
    }
    .input__field__increment,
    .input__field__decrement {
        display: none;
    }
    :root, html {
        background-color: #fff;
    }
    #page_wrap {
        width: 100vw;
        /* min-height: 100vh; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    main.page-calc {
        display: grid;
        grid-template:
            'inputs report' auto
            'graph graph' auto
            'info info' auto;
        padding: 2rem;
        gap: 2rem;
        justify-content: center;
    }
    .input__field__label:after {
        content:':'
    }
    .inputs_wrap {grid-area: inputs}
    .calculation_report {grid-area: report}
    .calculation_report * {max-width: 100%;}
    main.page-calc .info-instruct {grid-area: info; align-content: center; justify-content: center;}
    .info-instruct * {width: 100%; max-width: 100% !important;}
    .outputs_graph {grid-area: graph;}
    .outputs_graph>* {width: min(100% - 2rem);}
    .outputs_graph svg {width: 60vw;margin: auto;}
    .page-calc .outputs__heading {
        font-size: 1.5rem
    }
    .fields__wrap {
        gap: .25rem
    }
    .inputs__heading {
        display: none;
    }
    main.page-calc {
        max-width: calc(100vw - 1in);
    }
    .report__unit {
        opacity: 1;
    }
    #page_wrap ::placeholder {
        color: hsl(195deg 5% 10%) !important;
        opacity: 1;
        /* Firefox */
    }
    button {
        display: none;
    }
}
@media print AND (max-aspect-ratio: 1/1) {
    main.page-calc {
        display: grid;
        grid-template:
            'inputs' auto
            'report' auto
            'graph' auto
            'info' auto;
        justify-items: center;
        padding: 0rem;
    }
    .inputs_wrap .inputs_wrap__inner--panels_and_price {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: auto;
        justify-content: space-evenly;
        align-content: flex-start;
        align-items: flex-start;
        display: grid;
        grid-template:
            "panels qty" auto
            "price qty" auto
            "none none" 1fr / 1fr 1fr;
        align-items: start;
        height: auto;
    }
    .inputs__usage .fields__wrap {
        flex-wrap: wrap;
        height: 9rem;
    }
    main.page-calc section {
        min-width: min(var(--page-width) * 0.5 + 50vw);
        align-content: center;
    }
}
@media print AND (min-aspect-ratio: 1/1) {
    main.page-calc {
        display: grid;
        grid-template:
            'inputs report' auto
            'inputs graph' auto
            'info graph' auto / 1fr 1fr;
        padding: 1rem;
    }
    main.page-calc .outputs_graph {
        min-width: unset;
        max-width: unset;
        width: min(50vw - 8rem);
    }
    main.page-calc .outputs_graph>* {
        min-width: unset;
        max-width: unset;
        width: 100%;
        margin: 0 0 0 1rem;
    }
    .inputs_wrap {
        flex-wrap: nowrap;
    }
}

#page_wrap.og_image header,
#page_wrap.og_image footer {
    display: none
}
#page_wrap.og_image main.page-calc {
    display: grid;
    grid-template: "outputs_graph" 1fr / 1fr;
    padding: 2.5rem
}
#page_wrap.og_image .outputs__heading {
    display: none
}
#page_wrap.og_image main.page-calc section.outputs_graph {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
}
#page_wrap.og_imagesection.outputs_graph>svg {
overflow: visible;width: 64%;margin: 0 0 0 -30%;z-index: -2;transform: perspective(100vw) rotateY(15deg);
    position: relative;
    z-index: 3
}
#page_wrap.og_imagesection.outputs_graph>svg:nth-last-of-type(2) text,
#page_wrap.og_imagesection.outputs_graph>svg:nth-last-of-type(2) path {
    fill: hsl(0 0% 100%/.8) !important;
    position: relative;
    z-index: 3
}
#page_wrap.og_imagesection.outputs_graph>svg:nth-last-of-type(1) {
    transform: perspective(100vw) rotateY(-25deg);
    margin-left: -86%;
    width: 72%;
    position: relative;
    left: -20%;
    z-index: -1
}
#page_wrap.og_image main.page-calc section:not(.outputs_graph) {
    display: none;
}